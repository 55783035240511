@font-face {
  font-family: 'HenrySansRegular';
  src: local('HenrySansRegular'), url(./fonts/HenrySans-Regular.woff2) format('woff');
}

@font-face {
  font-family: 'HenrySansBold';
  src: local('HenrySansBold'), url(./fonts/HenrySans-Bold.woff2) format('woff');
  font-weight: bold;
}


body {
  margin: 0;
  font-family: 'HenrySansRegular'
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
